/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <h1 class="add-global-modal-main-title">Ventas en CFDI</h1>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de ventas en el cfdi: <b>{{ salesInformation.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="salesInformation"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        scrollable
        ref="salesInformation"
        :checked-rows.sync="checkedSales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_VENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
      >
        <template #empty>
          <div class="has-text-centered">No hay ventas en el</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <p>No hay información que mostrar</p>
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { openConfirmationModalDanger } from "../../../utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "GlobalInvoiceSales",
  props: ["salesInvoice"],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 25,
      checkedSales: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CLAVE_VENTA",
            label: "Folio",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusFormatted",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusFacFormatted",
            label: "Estatus de timbrado",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusDeliveryFormatted",
            label: "Estatus de envio",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "posFormatted",
            label: "Punto de venta",
            sortable: true,
            searchable: true,
            display: true,
          },

          {
            field: "NOMBRE_CLIENTE",
            label: "Cliente",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALM",
            label: "Almacen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_VENDEDOR",
            label: "Vendedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALMACEN_ORIGEN",
            label: "Origen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "formatedCreationDate",
            label: "Fecha de la venta",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "SUBTOTAL",
            label: "Subtotal",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "IVA",
            label: "Iva total",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "TOTAL",
            label: "Total",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
        ],
        "global-invoice-sales-list-columns"
      ),
      masks: Masks,
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    async refreshInfo() {
      let allPromises = [];
      allPromises.push(
        this.$store.dispatch("GETSALES", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          endDate: new Date(),
          warehouses: [],
        })
      );
      allPromises.push(
        this.$store.dispatch("GETQUOTES", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          endDate: new Date(),
          warehouses: [],
        })
      );
      await Promise.all(allPromises);
    },
    formatSale(sale) {
      let currentItems = [];
      for (let singleArticle of sale.VENTA_DET) {
        currentItems.push({
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          presentation: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
          priceList: singleArticle.LISTA_PRECIOS,
          quantity: singleArticle.CANTIDAD_PE,
          quantitySended: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          quantitySendedOriginal: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          RENGLON: singleArticle.RENGLON,
          price: singleArticle.COSTO_UNI,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          total: singleArticle.TOTAL,
          costTotal: singleArticle.COST_GTO,
          discountTotal: singleArticle.DESC_GTO,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO ? singleArticle.EXENTO : "N",
          COSTO_MIN_VTA: singleArticle.COSTO_MIN_VTA,
          UTILIDAD_UNI: singleArticle.utilityTotal,
          UTILIDAD: singleArticle.utilityTotal,
        });
      }
      let saleInformation = {
        _id: sale._id,
        CLAVE_VENTA: sale.CLAVE_VENTA,
        CLIENTE: sale.CLAVE_CLIENTE._id,
        ALMACEN: sale.CLAVE_ALMACEN._id,
        COSTOS: sale.COSTOS ? sale.COSTOS : [],
        DESCS: sale.DESCS ? sale.DESCS : [],
        OBSERVA: sale.OBSERVA,
        OBSERVA_IMP: sale.OBSERVA_IMP,
        currentItems: currentItems,
        FECHA: sale.FECHA,
        subtotal: sale.SUBTOTAL,
        total: sale.TOTAL,
        discounts: sale.DESC_GTO,
        costs: sale.COST_GTO,
        iva: sale.IVA,
        ieps: sale.IEPS,
        exempt: sale.EXENTO,
        shippingQuantity: sale.FLETE,
        shippingPercentage: sale.PORC_FLETE,
        printTimes: sale.IMPRESO,
        STATUS: sale.STATUS,
        EXENTO: sale.EXENTO,
        CLAVE_USO_SAT: sale.CLAVE_USO_SAT,
        CLAVE_REGIMEN: sale.CLAVE_REGIMEN,
        METODO_P: sale.METODO_P,
        CONDICION_P: sale.CONDICION_P,
        PERI_PAGO: sale.PERI_PAGO,
        FORMA_P: sale.FORMA_P,
        REFERENCIA: sale.REFERENCIA,
        NO_CUENTA: sale.NO_CUENTA,
        NO_CLABE: sale.NO_CLABE,
        NO_TARJETA: sale.NO_TARJETA,
        NO_CHEQUE: sale.NO_CHEQUE,
        STATUS_FAC: sale.STATUS_FAC,
        requireInvoice: sale.requireInvoice,
        requireInvoiceAfterSend: sale.requireInvoiceAfterSend,
        requireParcialInvoiceAfterSend: sale.requireParcialInvoiceAfterSend,
        relateCFDI: sale.relateCFDI,
        divideCFDI: sale.divideCFDI,
        UUID_CFDI: sale.UUID_CFDI,
        TIPO_R: sale.TIPO_R,
        VENDEDOR: sale.VENDEDOR,
        UTILIDAD: sale.UTILIDAD,
        DESBLOQUEO_CLIENTE: sale.DESBLOQUEO_CLIENTE,
        DESBLOQUEO_ALMACEN: sale.DESBLOQUEO_ALMACEN,
        sendMaterial: sale.sendMaterial,
        STATUS_ENV: sale.STATUS_ENV,
        useAdvancedPayment: sale.useAdvancedPayment,
        CLAVE_ANTICIPO: sale.CLAVE_ANTICIPO,
        CANTIDAD_ANTICIPO: sale.CANTIDAD_ANTICIPO,
        FECHA_ENVIO: sale.FECHA_ENVIO,
        clientPickup: sale.clientPickup,
        OC_CLIENTE: sale.OC_CLIENTE,
        USUARIO_CREACION: sale.USUARIO_CREACION,
        USUARIO_CONFIRMACION: sale.USUARIO_CONFIRMACION,
        CLAVE_VEHICULO: sale.CLAVE_VEHICULO,
        CODIGO_ENVIO: sale.CODIGO_ENVIO,
        RAZON_SOCIAL: sale.RAZON_SOCIAL,
      };
      return saleInformation;
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    async changeDateFilterValue() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(this.endDate).isBefore(this.startDate)) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETSALES", {
            startDate: this.startDate,
            endDate: this.endDate,
            warehouses: [],
          })
        );

        await Promise.all(allPromises);
      }
    },
    searchMoney(a, input) {
      console.log(a, input);
      return true;
    },
    checkCanDelete(saleInformation) {
      const isToday = moment(saleInformation.FECHA).isSame(moment(), "day");
      return (
        (this.companyPaymentPlanModules.sales.cancelSale === "S" &&
          this.permissions.sales.cancelSale === "S") ||
        (this.companyPaymentPlanModules.sales.dailyCancelSale === "S" &&
          this.permissions.sales.dailyCancelSale === "S" &&
          isToday)
      );
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "global-invoice-sales-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    salesInformation() {
      return this.salesInvoice.map((singleSale) => {
        const saleInformation = singleSale.CLAVE_VENTA;
        const saleWithProps = { ...saleInformation };

        saleWithProps.TOTAL = String(singleSale.CLAVE_VENTA.TOTAL);

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (saleWithProps.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = saleWithProps.CLAVE_ALMACEN.NOMBRE_ALM;
        }

        if (saleWithProps.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            saleWithProps.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }

        saleWithProps.formatedCreationDate = moment(saleWithProps.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );

        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        }

        if (!saleWithProps.STATUS_ENV || saleWithProps.STATUS_ENV === "P") {
          saleWithProps.statusDeliveryFormatted = "Pendiente";
        } else if (saleWithProps.STATUS_ENV === "I") {
          saleWithProps.statusDeliveryFormatted = "Incompleto";
        } else if (saleWithProps.STATUS_ENV === "C") {
          saleWithProps.statusDeliveryFormatted = "Completo";
        }

        return saleWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
